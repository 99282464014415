@function px2rem( $px ){
    @return $px*320/$designWidth/20 + rem;
}
$designWidth : 750;
*{max-height: 9999999999px;padding: 0;margin: 0;box-sizing: border-box;font-family: 'Microsoft YaHei';-webkit-tap-highlight-color:rgba(0,0,0,0);}
/* .bt-nav{
	padding:px2rem(13) 0 px2rem(13) px2rem(80);
	border-top:1px solid #eee;
	position:fixed;
	left:0;
	bottom:0;
	z-index: 9;
	background:#fff;
	width:px2rem(750);
	ul{
		li{
			float:left;
			a{
				display:block;
				text-align: center;
				width:px2rem(80);
				i{
                    background: url('../images/bt_tips1_09.png') no-repeat center center;
                    background-size: px2rem(40) px2rem(38);
                    display:block;
                    width:px2rem(80);
                    height:px2rem(38);
                    &.active{
                        background: url('../images/bt_tips1_active_09.png') no-repeat center center;
                        background-size: px2rem(40) px2rem(38);                        	
                    }
				}
				span{
					font-size: px2rem(21);
					color:#000;
					display:block;
					&.active{
						color:#ec632b
					}
				}
			}
			&:nth-of-type(2){
				margin:0 px2rem(180);
				a{
					display:block;
					text-align: center;
					width:px2rem(80);
					i{
                        background: url('../images/bt_tips2_03.png') no-repeat center center;
                        background-size: px2rem(40) px2rem(38);
                        display:block;
                        width:px2rem(80);
                        height:px2rem(38);
                        &.active{
	                        background: url('../images/bt_tips2_active_03.png') no-repeat center center;
	                        background-size: px2rem(40) px2rem(38);                        	
                        }
					}
					span{
						font-size: px2rem(21);
						color:#000;
						display:block;
						&.active{
							color:#ec632b
						}
					}
				}					
			}
			&:nth-of-type(3){
				a{
					display:block;
					text-align: center;
					width:px2rem(80);
					i{
                        background: url('../images/bt_tips3_06.png') no-repeat center center;
                        background-size: px2rem(40) px2rem(38);
                        display:block;
                        width:px2rem(80);
                        height:px2rem(38);
                        &.active{
	                        background: url('../images/bt_tips3_active_06.png') no-repeat center center;
	                        background-size: px2rem(40) px2rem(38);                        	
                        }
					}
					span{
						font-size: px2rem(21);
						color:#000;
						display:block;
						&.active{
							color:#ec632b
						}
					}
				}					
			}				
		}
	}
} */
.bt-nav{
	//padding:px2rem(13) 0 px2rem(13) px2rem(80);
	border-top:1px solid #eee;
	position:fixed;
	left:0;
	bottom:0;
	z-index: 9;
	background:#fff;
	width:px2rem(750);
	height:px2rem(100);
	ul{
		li{
			float:left;
			width:px2rem(375);
			a{
				display:block;
				text-align: center;
				width:px2rem(375);
				margin-top:px2rem(20);
				i{
                    background: url('../images/bt_tips1_09.png') no-repeat center center;
                    background-size: px2rem(40) px2rem(38);
                    display:block;
                    width:px2rem(80);
                    height:px2rem(38);
                    margin:auto;
                    &.active{
                        background: url('../images/bt_tips1_active_09.png') no-repeat center center;
                        background-size: px2rem(40) px2rem(38);                        	
                    }
				}
				span{
					font-size: px2rem(21);
					color:#000;
					display:block;
					&.active{
						color:#ec632b
					}
				}
			}
			&:nth-of-type(2){
				a{
					i{
                        background: url('../images/bt_tips2_03.png') no-repeat center center;
                        background-size: px2rem(40) px2rem(38);
                        display:block;
                        width:px2rem(80);
                        height:px2rem(38);
                        margin:auto;
                        &.active{
	                        background: url('../images/bt_tips2_active_03.png') no-repeat center center;
	                        background-size: px2rem(40) px2rem(38);                        	
                        }
					}
				}					
			}				
		}
	}
}