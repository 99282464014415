@import 'px2rem';
$designWidth : 750;

*{padding: 0;margin: 0;}
input[type="file"] {
  color: transparent;
  width:px2rem(50);
  height:px2rem(66);
  display:block;
  opacity:0; 
}
    .index-wrap{
        width:px2rem(750);
        margin:0 auto;
        .index-top{
           width: px2rem(750); 
           overflow: hidden;
           padding:px2rem(20) 0 px2rem(20) px2rem(20);
           .index-logo{
              width: px2rem(78);
              height: px2rem(84);
              display:block; 
           }
           .search-box{
              width:px2rem(523);
              height:px2rem(66);
              margin-left: px2rem(20);
              background: url('./../images/index_search_bg_03.png') no-repeat;
              background-size: cover;
              margin-top: px2rem(10);
              i{
                width:px2rem(34);
                height:px2rem(34);
                background: url('./../images/search_bg_09.png') no-repeat;
                background-size: cover; 
                display:inline-block;  
                margin-left: px2rem(20);             
              }
              form{
                display:inline-block;
                input{
                  border:none;
                  background:#f4f4f4;
                  font-size: px2rem(26); 
                  display:inline-block;
                  width:px2rem(400); 
                  margin-top: px2rem(-10);            
                } 
                input[type="search"]::-webkit-search-cancel-button{ 
                   display: none;
                   -webkit-appearance: none;
                }  

              }
           }
           .img-upload{
              background: url('./../images/upload_img_06.png') no-repeat;
              background-size: cover;
              width:px2rem(71);
              height:px2rem(66); 
              margin-top: px2rem(10);                            
           }
        }
        .swiper{
          width:px2rem(750);
          height:px2rem(320);
          img{
           width:px2rem(750);
           height:px2rem(320);           
          }
          .swiper-pagination-bullet{
              width: px2rem(16);
              height: px2rem(16);
              display: inline-block;
              border-radius: 100%;
              background: #d3cec8;
              opacity: .5
          }
          .swiper-pagination-bullet-active{
              opacity: 1;
              border-radius: 33%;
              width: px2rem(30);
              height: px2rem(15);
              background: #FFF;
          }
          .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet{
              margin:0 px2rem(10);
          }
          .swiper-container-horizontal>.swiper-pagination-bullets{
              bottom: 10px;
              left: px2rem(0);
              width: 100%
          }
        }
        .index-content{
          background:#eee;
          padding-top: px2rem(45);
          padding-bottom: px2rem(85);
          .con-top{
              width:px2rem(571);
              height:px2rem(41);
              margin: 0 auto;
              background: url('./../images/index_sizhong_03.png') no-repeat;
              background-size: cover;            
          }
          .index-tabs{
            width:px2rem(750);
            margin:px2rem(45) 0 px2rem(0) 0;
            li{
              float: left;
              width:px2rem(120);
              margin-right:px2rem(72);
              &:last-child{
                margin-right:0;
              }
              &:first-child{
                margin-left:px2rem(10);
              }           
              a{
                text-align: center;
                span{
                  color: #333; 
                  font-size: px2rem(26);
                  display:block; 
                  margin-top:px2rem(15);
                }
                img{
                  width:px2rem(120);
                  height:px2rem(120); 
                  display:inline-block;                   
                }
                display:block;            
                width:px2rem(150);             
              }
            }
          } 
          .index-anli{
            .index-anli-con{
              position: relative;
              background:#FFF;
              margin-top: px2rem(24);
              margin-left: px2rem(24);
              width:px2rem(702);
              .anli-img-box{
                display:block;
                width:px2rem(702);
                height:px2rem(375);                
                .anli-img{
                  @extend .anli-img-box;             
                }
                .anli-meter{
                  position:absolute;
                  right:px2rem(18);
                  top:px2rem(250);
                  font-size: px2rem(18);
                  color:#fff;
                  //width:px2rem(86);
                  //height:px2rem(44);
                  padding:px2rem(0) px2rem(10);
                  line-height: px2rem(44);
                  text-align: center;
                  border-radius: px2rem(25);
                  background-color: rgba(222,109,44,0.8);
                }
                .anli-room{
                  @extend .anli-meter;
                  top:px2rem(310)
                }
              }
              .anli-tx{
                position:absolute;
                left:px2rem(18);
                top:px2rem(305);
                background:#FFF;
                border-radius: px2rem(25);
                //width:px2rem(180);
                height:px2rem(45);
                line-height: px2rem(45);
                .anli-tx-img{
                  width:px2rem(42);
                  height:px2rem(42);
                  border-radius:50%;
                  margin-left: 1px;
                  display:block;
                }
                span{
                  font-size: px2rem(18);
                  color:#333;
                  margin-left: px2rem(8);
                  padding-right: px2rem(10);
                }
              }
              .anli-bt{
                margin-left: px2rem(15);
                h3{
                  font-size: px2rem(34);
                  color:#333;
                  margin:px2rem(20) 0;
                  em{
                    color:#ec632b;
                  }
                }
                p{
                  font-size: px2rem(26);
                  color:#333;
                }
                .anli-bt-txt{
                  margin-top:px2rem(11);
                  padding-bottom: px2rem(20);
                  p{
                    color:#666;
                    font-size: px2rem(22);
                    span{
                      color:#ec632b;
                    }
                  }
                  .anli-bt-line{
                    font-size: px2rem(22);
                    margin:0 px2rem(25);
                  }
                }
              }
            }
          } 
          .find-designer{
            color:#333;
            font-size: px2rem(34);
            margin:px2rem(70) 0 px2rem(35) px2rem(40);
          }
          .index-designer-wrap{
            a{
              display:block;
              background:#fff;
              width:px2rem(700);
              margin-left:px2rem(20);
              position:relative;
              margin-bottom: px2rem(24);
              .index-video-img{
                position:absolute;
                right:px2rem(35);
                bottom:px2rem(18);
                width:px2rem(40);
                height:px2rem(40);
              }
            }
            .index-designer-box{
              i{
                width:px2rem(4);
                height:px2rem(180);
                background: url('./../images/index_line_27.png') no-repeat;
                background-size: cover; 
              }
              img{
                width:px2rem(120);
                height:px2rem(120);
                display:block;
                border-radius:50%;
                margin:px2rem(27) px2rem(23) 0 px2rem(12);
              }
              .index-designer-txt{
                width:px2rem(500);
                margin-top:px2rem(27);
                h4{
                  font-size: px2rem(26);
                  color:#333;
                }
                p{
                  font-size: px2rem(24);
                  color:#999;
                  line-height: px2rem(40);
                }
              }
            }
          } 
          .index-hot-img{
            ul{
              margin-left: px2rem(25);
              li{
                float:left;
                margin-right: px2rem(24);
                margin-bottom: px2rem(24);
                a{
                  width:px2rem(336);
                  height:px2rem(252);
                  display:block;
                  img{
                    @extend a;
                  }
                }
              }
            }
          }       
        }
    }
